import { useNetworkStore } from "@/store/NetworkStore";

import { DsNotifier } from "@devsalsa/vue-core";
import { useDsToastNotifierStore } from "@devsalsa/vue-core";

import i18n from "@/i18n";

export default {
  install() {
    // configure the app
    window.addEventListener("offline", () => {
      useNetworkStore().setOffline();
      if (useDsToastNotifierStore().networkNotificationId === 0) {
        const id = DsNotifier.sendInfo({
          message: i18n.global.t("common.error.global.offline"),
          duration: 0,
          enableClose: false,
          position: "top-0 right-0",
          overlay: true,
        });
        useDsToastNotifierStore().setNetworkId(id);
      }
    });

    window.addEventListener("online", () => {
      useNetworkStore().setOnline();
      DsNotifier.delete(useDsToastNotifierStore().networkNotificationId);
      useDsToastNotifierStore().setNetworkId(0);
    });
  },
};
