import { useAppNotifierStore } from "@/store/AppNotifierStore";

import NotificationService from "@/modules/notification/services/NotificationService";

import type { AppNotification } from "@/modules/notification/services/NotificationService.types";

export interface AppNotificationMessage {
  message: AppNotification;
  duration?: number;
  enableClose?: boolean;
}

export interface AppToastNotification extends AppNotificationMessage {
  themeColor: string;
  duration: number;
  enableClose: boolean;
}

export interface AppStoreNotification extends AppToastNotification {
  id: number;
}

export default class AppNotifier {
  static readonly SUCCESS = "success";
  static readonly NOTICE = "notice";
  static readonly CRITICAL = "critical";
  static readonly WARNING = "warning";

  static sendNotification(notification: AppNotification) {
    let toastNotification: AppToastNotification;
    if (notification.app_notification_style === AppNotifier.SUCCESS) {
      toastNotification = {
        message: notification,
        themeColor: "green",
        duration: 10,
        enableClose: true,
      };
    } else if (notification.app_notification_style === AppNotifier.NOTICE) {
      toastNotification = {
        message: notification,
        themeColor: "blue",
        duration: 10,
        enableClose: true,
      };
    } else if (notification.app_notification_style === AppNotifier.CRITICAL) {
      toastNotification = {
        message: notification,
        themeColor: "red",
        duration: 10,
        enableClose: true,
      };
    } else if (notification.app_notification_style === AppNotifier.WARNING) {
      toastNotification = {
        message: notification,
        themeColor: "yellow",
        duration: 10,
        enableClose: true,
      };
    } else {
      toastNotification = {
        message: notification,
        themeColor: "gray",
        duration: 10,
        enableClose: true,
      };
    }

    return AppNotifier.addStoreNotification(toastNotification);
  }

  private static addStoreNotification(notification: AppToastNotification) {
    useAppNotifierStore().add({
      id: notification.message.id,
      ...notification,
    });
    return notification.message.id;
  }

  static deleteStoreNotification(id: number) {
    useAppNotifierStore().delete(id);
  }

  static async markRead(notificationId: number): Promise<void> {
    await NotificationService.markRead(notificationId);
  }

  static async markReadAll(): Promise<void> {
    await NotificationService.markReadAll();
  }

  static async delete(notificationId: number): Promise<void> {
    await NotificationService.delete(notificationId);
  }

  static async deleteAll(): Promise<void> {
    await NotificationService.deleteAll();
  }
}
