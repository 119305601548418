import { defineStore } from "pinia";

import type { AppStoreNotification } from "@/core/shared/helpers/Notifier/AppNotifier";

export interface NewAppNotifications {
  unread_notifications: number;
}

export interface AppNotifierState {
  notifications: AppStoreNotification[];
  unreadNotifications: number;
}

export const useAppNotifierStore = defineStore("appNotifier", {
  state: (): AppNotifierState => ({
    notifications: [] as AppStoreNotification[],
    unreadNotifications: 0,
  }),
  actions: {
    add(notification: AppStoreNotification): void {
      this.notifications.push(notification);
    },
    delete(id: number): void {
      const index = this.notifications.findIndex((notification) => {
        return notification.id === id;
      });
      //If index found, delete the notification
      if (index !== -1) {
        this.notifications.splice(index, 1);
      }
    },
    setUnread(totalUnread: number): void {
      this.unreadNotifications = totalUnread;
    },
  },
});
