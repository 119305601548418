import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

import { createGtm } from "@gtm-support/vue-gtm";

import router from "@/router";

export default createGtm({
  id: import.meta.env.VITE_APP_GTM_ID,
  queryParams: {
    gtm_auth: import.meta.env.VITE_APP_GTM_AUTH,
    gtm_preview: import.meta.env.VITE_APP_GTM_PREVIEW,
    gtm_cookies_win: "x",
  },
  vueRouter: router,
  vueRouterAdditionalEventData: async () => {
    try {
      if (AccountHandler.isAuthenticated()) {
        const accountInfo = await AccountHandler.getInfo();
        const impersonating = accountInfo.admin_id && accountInfo.admin_id > 0;
        return {
          user: {
            id: `C${accountInfo.id}`,
            email: accountInfo.email,
            name: accountInfo.first_name
              ? `${accountInfo.first_name} ${accountInfo.last_name}`
              : "",
            created: accountInfo.date_created,
            type: "creator",
          },
          version: import.meta.env.PACKAGE_VERSION,
          impersonating: impersonating ? 1 : 0,
        };
      }

      return {};
    } catch (e) {
      //We can't get the user information, it doesn't matter.
      return {};
    }
  },
});
