<template>
  <div class="app-notifier">
    <AppToast
      v-for="item in queueMessages()"
      :key="item.id"
      :unique="item.id"
      :message="item.message"
      :theme-color="item.themeColor"
      :duration="item.duration"
      :enable-close="item.enableClose"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useAppNotifierStore } from "@/store/AppNotifierStore";

import AppToast from "@/core/shared/components/Toast/AppToast.vue";
import type { AppStoreNotification } from "@/core/shared/helpers/Notifier/AppNotifier";

/**
 * ApiNotifier component
 */
export default defineComponent({
  name: "AppNotifier",
  components: {
    AppToast,
  },
  data() {
    return {
      appNotifierStore: useAppNotifierStore(),
      maxQueue: 5,
    };
  },
  methods: {
    queueMessages() {
      const internalMessages: Array<AppStoreNotification> = [];
      let i = 0;
      this.appNotifierStore.notifications.forEach((reg) => {
        if (i < this.maxQueue) {
          internalMessages.push(reg);
          i++;
        }
      });

      return internalMessages;
    },
  },
});
</script>

<style lang="scss" scoped>
.app-notifier {
  @apply fixed bottom-5 right-2.5 sm:right-5 space-y-3 z-30;
}
</style>
